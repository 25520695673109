import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  CalendarErrors,
  FilterOptions,
  Optional,
} from '../../../../types/types';
import * as _ from 'lodash';
import { MemoizedViewModalFactory } from '../viewModel';

export type NoAvailableSlotsViewModel = {
  noSessionsOfferedText: string;
  noUpcomingTimeSlotsText?: string;
  checkAvailabilityCTA?: {
    text: string;
    isFullWidth: boolean;
  };
};

export const memoizedNoAvailableSlotsViewModel: MemoizedViewModalFactory<NoAvailableSlotsViewModel> =
  {
    dependencies: {
      state: ['calendarErrors', 'filterOptions'],
      settings: [
        'noSessionsOffered',
        'goToNextAvailableDate',
        'noUpcomingTimeSlots',
        'buttonsFullWidth',
      ],
    },
    createViewModel: createNoAvailableSlotsViewModel,
  };

export function createNoAvailableSlotsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): NoAvailableSlotsViewModel {
  const { getContent, settings, settingsParams } = context;
  const { calendarErrors, filterOptions } = state;

  const noSessionsOfferedText = getContent({
    settingsParam: settingsParams.noSessionsOffered,
    translationKey: 'app.settings.defaults.time-picker.no-slots-message',
  });

  const isNoNextAvailableDate = calendarErrors.some(
    (error) => error === CalendarErrors.NO_NEXT_AVAILABLE_DATE_WARNING,
  );
  const isNextAvailableDateServerError = calendarErrors.some(
    (error) => error === CalendarErrors.NEXT_AVAILABLE_DATE_SERVER_ERROR,
  );

  const noUpcomingTimeSlotsText = getNoUpcomingTimeSlotsText({
    isNoNextAvailableDate,
    filterOptions,
    context,
  });

  const showCheckAvailabilityCTA =
    !isNoNextAvailableDate && !isNextAvailableDateServerError;

  const checkAvailabilityCTA = showCheckAvailabilityCTA
    ? {
        text: getContent({
          settingsParam: settingsParams.goToNextAvailableDate,
          translationKey:
            'app.settings.defaults.time-picker.go-to-next-available-day',
        }),
        isFullWidth: settings.get(settingsParams.buttonsFullWidth),
      }
    : undefined;

  return {
    noSessionsOfferedText,
    noUpcomingTimeSlotsText,
    checkAvailabilityCTA,
  };
}

function getNoUpcomingTimeSlotsText({
  isNoNextAvailableDate,
  filterOptions,
  context,
}: {
  isNoNextAvailableDate: boolean;
  filterOptions: FilterOptions;
  context: CalendarContext;
}): Optional<string> {
  const { getContent, t, experiments, settingsParams } = context;
  if (isNoNextAvailableDate) {
    const areFilterExists = _.every(filterOptions, 'length');
    if (areFilterExists) {
      return t(
        'app.time-picker.notifications.no-next-available-date-matching-filters',
      );
    }

    return getContent({
      settingsParam: settingsParams.noUpcomingTimeSlots,
      translationKey: 'app.time-picker.notifications.no-upcoming-slots',
    });
  }
}
